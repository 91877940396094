import React from 'react'

function TestimonialBox(props) {
    return (
        <div className="testimonial-item box">
            <p>"{props.description}"</p>
            <div className="testimonial-author">
                <span className="author">{props.author}</span>
                <span className="company">{props.company}</span>
            </div>
        </div>
    )
}

export default TestimonialBox
