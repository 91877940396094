import React from 'react'
import Slider from "react-slick";
import TestimonialBox from '../components/TestimonialBox'




export default function Testimonial () {

    const settings = {

        dots: false,
        
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
             
            }
            },
            {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            },
            {
            breakpoint: 700,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }


        ]}


    return (
        <div className="testimonial-slider">
            <div className="page-container">
                <div className="section-text">
                    <h2>What our clients say</h2>
                </div>
                <div className="slider">

                    <Slider {...settings}>
                        <TestimonialBox
                        
                        description="Penfold Design was able to produce me a website that captured my
                        brand’s attributes with a strong attention to detail. The service delivered was in a professional manner and a commitment to deadlines."

                        author="Dan Graham"
                        company="Aluses Graphics"
                        
                        />
                        <TestimonialBox
                        
                        description="Aaron shows highly compelling intellect when working
                        on the design and frame of our websites. I would recommend to any start up
                        company looking for responsive, cost effective web solutions"

                        author="Jack Young"
                        company="Scopic Freelancers"
                        
                        />
                        <TestimonialBox
                        
                        description="Choosing Penfold Design for our website was a no-brainer. He took the time
                        to get to know us as a brand before even working on the website; I would definitely recommend."

                        author="Liam Kelleher"
                        company="Distorted Clothing"
                        
                        />
                        <TestimonialBox
                        
                        description="I will for sure be in touch with some more work later down the line. Service and quality of your work is fantastic! "

                        author="Harvey Malik"
                        company="Chained & Charged"
                        
                        />
                  
                    </Slider>

                </div>
            </div>
        </div>
    )
}


