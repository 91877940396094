import React from 'react'
import {Link} from 'gatsby'

export const PortfolioBox = (props) => {
    return (
        <Link to={props.url} target="_blank">
            <div className="portfolio-box">
                <div className="portfolio-image">
                    
                    <img src={props.image} alt={props.title}></img>
                
                </div>
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            
            </div>
        
        
        </Link>
    )
}

export default PortfolioBox
