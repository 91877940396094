import React from 'react'
import ServiceBox from '../components/ServiceBox'
import Button from '../components/Button'
//Image Imports
import WebDesign from '../images/illustrations/web-design.png'
import WebDevelopment from '../images/illustrations/web-development.png'
import BrandIdentity from '../images/illustrations/brand-identity.png'
import ManagedHosting from '../images/illustrations/managed-hosting.png'
import ContentCreation from '../images/illustrations/content-creation.png'


function Services() {
    return (
        <div className="services-section" id="services">
            <div className="page-container">
                    <div className="section-text">
                        <h2>Solutions that make a difference</h2>
                        <p>Working closely with our clients, we ensure that a web solution not only meets their specific requirements 
                        but provides them with the tools they need to power through their online presence.</p>
                    </div>
                    <div className="services-container">
                        <ServiceBox
                                type="web-design"
                                title="Web Design"
                                description="Each website we design is crafted exactly to what the client requires, using pre-built templates is the last thing on our mind."
                                to="#portfolio"
                                anchortext="View our portolio"
                                image={WebDesign}
                            
                            />
                        <ServiceBox
                                type="web-dev"
                                title="Web Development"
                                description="We develop quick, modern, mobile-friendly websites over multiple different platforms, both static and dynamic. "
                                to="#portfolio"
                                anchortext="View our portolio"
                                image={WebDevelopment}
                            
                            />
                        <ServiceBox
                                type="brand-identity"
                                title="Brand Identity"
                                description="We offer a full range of graphical services to help get your brand uniform and professional."
                                to="#portfolio"
                                anchortext="View our portolio"
                                image={BrandIdentity}
                            
                            />
                        <ServiceBox
                                type="content-creation"
                                title="Content Creation"
                                description="We can curate content specifically towards your brand and help you show the good in what you do."
                                to="#portfolio"
                                anchortext="View our portolio"
                                image={ContentCreation}
                            
                            />
                        <ServiceBox
                                type="managed-hosting"
                                title="Managed Hosting"
                                description="We can manage all your hosting and domains for you, which makes it much easier for you to focus on what’s most important."
                                to="#portfolio"
                                anchortext="View our portolio"
                                image={ManagedHosting}
                            
                            />
                    </div>

                <Button
                
                to="#portfolio"
                title="View our portfolio"
                color="pink"
                
                />

            </div>
        </div>
    )
}

export default Services
