import React from 'react'
import Button from '../components/Button'
import axios from 'axios'
import * as qs from 'query-string'

class Contact extends React.Component {
    constructor(props){
        super(props)
        this.domRef = React.createRef()
        this.state = { feedbackMsg: null }
    }

    handleSubmit(event){
        event.preventDefault()
        const formData = {}
        Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))

        const axiosOptions = {
            url: "https://www.penfolddesign.co.uk",
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formData),
          }

          axios(axiosOptions)
    .then(response => {
      this.setState({
        feedbackMsg: "Great! Your request submitted successfully! 😁 ",
      })
      this.domRef.current.reset()
    })
    .catch(err =>
      this.setState({
        feedbackMsg: "Hmm...your request could not be submitted. 🤨",
      })
    )
    }
    
    render(){
    return (
        <div className="contact-section" id="contact">
            <div className="page-container">
                <div className="section-text">
                    <h2>Let's boost your online presence</h2>
                </div>
                <div className="form-container">
                    <form ref={this.domRef} name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" className="contact" onSubmit={event => this.handleSubmit(event)}>
                    <input type="hidden" name="bot-field" />
                    <input ref="form-name" type="hidden" name="form-name" value="contact" />
                    <div className="top-form-group">
                    <div className="form-group">
                        <label htmlFor="name">
                            Your Name
                        </label>
                        <input ref="name" type="text" name="name" placeholder="John Doe" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">
                            Your E-mail
                        </label>
                        <input  ref="email" type="email" name="email" placeholder="john.doe@example.com" required/>
                    </div>
                </div>
                    <div className="multi-select">
                            <label>Which service are you interested in?</label>
                            <span>(You can select multiple options if you’d like!)</span>

                            <div className="checkbox-container">
                                <div class="checkbox">
                                    <input  ref="webdev" type="checkbox" id="webdev" value="webdev"/>
                                    <label>
                                        Web Development
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input   ref="webdesign" type="checkbox" id="webdesign" value="webdesign"/>
                                    <label>
                                        Web Design
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input  ref="brandidentity" type="checkbox" id="brandidentity" value="brandidentity"/>
                                    <label>
                                        Brand Identity
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <input  ref="contentcreation" type="checkbox" id="contentcreation" value="contentcreation"/>
                                    <label>
                                        Content Creation
                                    </label>
                                </div>
                            </div>
                         
                    </div>
                    <div className="success">
                        {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}
                    </div>
                    <div className="submit-cta">
                    <button type="submit" class="main-button white-button">Submit greatness!</button>
                    </div>

                    </form>

                    

                </div>
                
            </div>
            
        </div>
    )
}
}

export default Contact
