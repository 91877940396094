import React from 'react'

import Illustration from '../images/illustrations/process.png'
import ProcessBox from './ProcessBox'

import { Icon, InlineIcon } from '@iconify/react';
import bxsPencil from '@iconify/icons-bx/bxs-pencil';
import keyboardFill from '@iconify/icons-bi/keyboard-fill';
import baselineDesignServices from '@iconify/icons-ic/baseline-design-services';
import testTube from '@iconify/icons-mdi/test-tube';
import tickIcon from '@iconify/icons-subway/tick';
import documentReport from '@iconify/icons-heroicons-solid/document-report';

export const Processes = () => {
    return (
        <div className="processes-section" id="approach">
            <div className="page-container">
                <div className="section-text">
                    
                    <h2>Our process-based approach</h2>
                    <p>All of our designs are made from scratch by our team, as we believe that’s the best way to portray your identity - by using the colors and styles that perfectly match it. We follow the same 6 step process-based approach for all our clients.</p>
                </div>

                <div className="process-container">
                    <ProcessBox
                    
                    id="1"
                    icon={<Icon icon={bxsPencil} style={{color: '#ffffff', fontSize: '24px'}} />}
                    number="1"
                    title="Client Requirements"
                    description="Create a brief of exactly what is wanted and the priorities required."
                    
                    />
                    <ProcessBox
                    
                    id="2"
                    icon={<Icon icon={keyboardFill} style={{color: '#ffffff', fontSize: '24px'}} />}
                    number="2"
                    title="Moodboard"
                    description="We gather components and inspiration which match the brief."
                    
                    />
                    <ProcessBox
                    
                    id="3"
                    icon={<Icon icon={baselineDesignServices} style={{color: '#ffffff', fontSize: '24px'}} />}
                    number="3"
                    title="Design"
                    description="Convert ideas into real life designs and provide evidence to the client."
                    
                    />
                    <ProcessBox
                    
                    id="4"
                    icon={<Icon icon={testTube} style={{color: '#ffffff', fontSize: '24px'}} />}
                    number="4"
                    title="Development & Testing"
                    description="Bring the design to life! And follow a strict action-based test plan."
                    
                    />
                    <ProcessBox
                    
                    id="5"
                    icon={<Icon icon={tickIcon} style={{color: '#ffffff', fontSize: '18px'}} />}
                    number="5"
                    title="Deploy"
                    description="Hand the finished site over to client, or deploy to the web."
                    
                    />
                    <ProcessBox
                    
                    id="6"
                    icon={<Icon icon={documentReport} style={{color: '#ffffff', fontSize: '24px'}} />}
                    number="6"
                    title="Maintenance"
                    description="Make sure that your website is running at max performance."
                    
                    />
                </div>

            </div>
            <div className="process-illustration">
                <img src={Illustration} alt="Process Illustration"/>
            </div>
        </div>
    )
}

export default Processes