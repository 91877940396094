import React, {useEffect} from "react"
import Helmet from 'react-helmet'
import Contact from '../components/Contact'
import Footer from '../components/Footer'


import LandingHero from '../components/LandingHero'
import Navigation from '../components/Navigation'
import Portfolio from '../components/Portfolio'
import Services from '../components/Services'
import StackIcon from '../components/StackIcon'
import Testimonial from '../components/Testimonial'
import Aos from "aos";
import "aos/dist/aos.css";
import Processes from "../components/Processes"

import Cover from '../images/cover.png'

const Home = () => {

  useEffect(() =>{
    Aos.init({

      once: true,
      startEvent: 'load'
    });

    Aos.refresh();

  }, []);


  return (
    
    <div className="penfolddesign-v4-gatsby"> 
      <Helmet>

      <title>Penfold Design - Modern Website Solutions</title>

      <meta name="title" content="Penfold Design - Modern Website Solutions"/>
      <meta name="description" content="Penfold Design create bespoke custom web solutions, to help you grow online with your customers. Be different, let us design you a website that really defines you. 📝"/>
      <html lang="en"/>

      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://www.penfolddesign.co.uk"/>
      <meta property="og:title" content="Penfold Design - Modern Website Solutions"/>
      <meta property="og:description" content="Penfold Design create bespoke custom web solutions, to help you grow online with your customers. Be different, let us design you a website that really defines you. 📝"/>
      <meta property="og:image" content={Cover}/>


      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="hhttps://www.penfolddesign.co.uk"/>
      <meta property="twitter:title" content="Penfold Design - Modern Website Solutions"/>
      <meta property="twitter:description" content="Penfold Design create bespoke custom web solutions, to help you grow online with your customers. Be different, let us design you a website that really defines you. 📝"/>
      <meta property="twitter:image" content={Cover}/>

      
      <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>
      <link rel="canonical" href="https://www.penfolddesign.co.uk"/>
      

      </Helmet>
      <div className="hero-background"></div>

      <Navigation/>
      <LandingHero/>
      <StackIcon/>
      <Services/>
      <Testimonial/>
      <Portfolio/>
      <Processes/>
      <Contact/>
      <Footer/>


    </div>
  )
}

export default Home
