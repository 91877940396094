import React from 'react'
import Logo from '../images/penfold-white-logo.png'

import { Icon, InlineIcon } from '@iconify/react';
import bxEnvelope from '@iconify/icons-bx/bx-envelope';
import instagramIcon from '@iconify/icons-fa-brands/instagram';
import linkedinIcon from '@iconify/icons-fa-brands/linkedin';
import whatsappIcon from '@iconify/icons-fa-brands/whatsapp';

export default function Footer() {
    return (
       <footer class="footer">
           <div className="footer-container">
                <div className="flex-container">
                    <img src={Logo} alt="Penfold Design White Logo"/>
                   
                    

                    <div className="social-icons">
                        <ul style={{padding: "0"}}>
                        <li class="menu-social-icons">
                                        <a href="https://instagram.com/penfolddesign" target="_blank" rel="noreferrer"><Icon icon={instagramIcon} style={{color: '#FFF', fontSize: '28px'}} /></a>
                                        <a href="https://instagram.com/penfolddesign" target="_blank" rel="noreferrer"><Icon icon={linkedinIcon} style={{color: '#FFF', fontSize: '28px'}} /></a>
                                        <a href="https://api.whatsapp.com/send?phone=07960269831" target="_blank" rel="noreferrer"><Icon icon={whatsappIcon} style={{color: '#FFF', fontSize: '28px'}} /></a>
                                    </li>
                        </ul>
                    </div>
                    {/* <span>Penfold Design 2020. All rights reserved.</span> */}
                </div>
           </div>


       </footer>
    )
}
