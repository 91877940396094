import React from 'react'
import Button from '../components/Button'
import LandingIllustration from '../images/LandingIllustration.png'

export default function LandingHero() {
    return (
        <div className="page-container">
            <div className="landing-hero-container">
                    <div className="landing-hero-contents">
                            <h1 >Website solutions with a <span style={{fontStyle:"italic"}}>modern</span> twist.</h1>
                            <p>Penfold Design create bespoke <span style={{fontWeight:"bold"}}>custom web solutions</span> to help you grow online with your customers. <span className="mobile-hide">Be different, let us design you a website that really defines you.</span> 📝</p>
                            <Button
                            
                            color="white"
                            to="#portfolio"
                            title="View our portfolio"
                            
                            />
                    </div>
                    <div className="landing-hero-illustration">
                        <img src={LandingIllustration} alt="Landing Illustration"/>
                    </div>
            </div>
        </div>
    )
}
