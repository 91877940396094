import React from 'react'
import {AnchorLink} from 'gatsby-plugin-anchor-links'

export default function ServiceBox(props){
    return (
        <div className={`service-box ${props.type}`}>
            <div className="service-title">
            <h3>{props.title}</h3>
            </div>
            <div className="service-description">
            <p>{props.description}</p>
            </div>

           <div className="serice-link">
           <AnchorLink
            to={props.to}
            title={`${props.anchortext} ›`}
            />
           </div>

            <div className="service-illustration">
                <img src={props.image} alt={`${props.type} illustration`} />
            </div>

        </div>
    )
}



