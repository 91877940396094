import React from 'react'

export const ProcessBox = (props) => {
    return (
        <div className="process-box" id={`box-${props.number}`}>
        <div className="process-id"><span className="process-icon">{props.icon}</span><span className="process-number">{props.number}</span></div>
        <div className="process-title"><h4>{props.title}</h4></div>
        <div className="process-description"><p>{props.description}</p></div>
    </div>
)
    
}

export default ProcessBox
