import React from 'react'
import PortfolioBox from '../components/PortfolioBox'
import Button from '../components/Button'

import ChainedandCharged from '../images/clients/chainedandcharged.png'
import DistortedClothing from '../images/clients/distortedclothing.png'
import Aluses from '../images/clients/aluses.png'
import ScopicFreelancers from '../images/clients/scopicfreelancers.png'
import ComingSoon from '../images/clients/comingsoon.png'

function Portfolio() {
    return (
        <div className="portfolio-section" id="portfolio">
            <div className="page-container">
                <div className="section-text">
                    <h2>What we've been working on</h2>
                    <p>We pride ourselves in providing the best web solutions for our clients, whatever the requirements - big or small. <span style={{fontWeight:"bold"}}>Click on a project</span> to be directed to our clients’ solutions.</p>
                </div>

                <div className="portfolio-grid">
                
                    <PortfolioBox
                    image={Aluses}
                    url="https://aluses.com"
                    title="Aluses Graphics"
                    description="Freelance graphic designer from Hampshire, UK. A fully responsive, modern, dynamic web solution."
                    />
                    <PortfolioBox
                    
                    image={DistortedClothing}
                    url="https://distortedclothing.co.uk"
                    title="Distorted Clothing"
                    description="Clothing company from Hampshire, UK. Minimalistic eCommerce solution, with full product tracking & management."
                    
                    />
                    <PortfolioBox
                    image={ChainedandCharged}
                    url="https://chainedandcharged.com"
                    title="Chained & Charged"
                    description="An electronic bike trader from Radstock, UK. Updated eCommerce web solution, including movement of platforms."
                    
                    />
                       <PortfolioBox
                    image={ComingSoon}
                    url="/"
                    title="Fat32 Studios"
                    description="Startup audio company from Toronto, CA. Dynamic web solution with integrated user profiles and forums."
                    
                    />
                    <PortfolioBox

                     image={ScopicFreelancers}
                    url="https://scopicfreelancers.co.uk"
                    title="Scopic Freelancers"
                    description="Freelance collective from Birmingham, UK. Responsive, services portfolio website with gallery."
                    
                    />
                    <PortfolioBox
                    image={ComingSoon}
                    url="/"
                    title="Subtle Speaks"
                    description="Wellbeing and mental health charity from Birmingham, UK. Modern, dynamic website with blog CMS integration."
                    
                    />
                 
                </div>

                <div className="portfolio-cta">
                <Button
                
                to="#contact"
                title="Want to talk more?"
                color="pink"

                />
                </div>

            </div>
            
        </div>
    )
}

export default Portfolio

