import React from 'react'
import { Icon, InlineIcon } from '@iconify/react';
import figmaIcon from '@iconify/icons-fa-brands/figma';
import wordpressIcon from '@iconify/icons-brandico/wordpress';
import reactIcon from '@iconify/icons-cib/react';
import netlifyIcon from '@iconify/icons-cib/netlify';
import sassIcon from '@iconify/icons-cib/sass';

export const StackIcon = () => {
    return (
        <div className="jamstack-icons">
            <div className="page-container">
                <div className="icons-flex">
                    <Icon className="stack-icon" icon={figmaIcon} style={{color: '#a5a5a5', fontSize: '50px'}} />
                    <Icon className="stack-icon" icon={wordpressIcon} style={{color: '#a5a5a5', fontSize: '50px'}} />
                    <Icon className="stack-icon" icon={reactIcon} style={{color: '#a5a5a5', fontSize: '50px'}} />
                    <Icon className="stack-icon" icon={netlifyIcon} style={{color: '#a5a5a5', fontSize: '50px'}} />
                    <Icon className="stack-icon" icon={sassIcon} style={{color: '#a5a5a5', fontSize: '50px'}} />
                </div>
            </div>
            
        </div>
    )
}

export default StackIcon
